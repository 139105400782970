.footer {
  width: 100%;
  padding: 3rem 3.875rem;
  background-color: #353535;
  display: flex;

}

.footerContent {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3rem;
  margin: auto;
}

.footerBottom {
  width: 100%;
  padding: 18px 11% 17px 11%;
  background: #282727;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 26px;
  text-align: center;

}


@media (min-width: 868px) {
  .footer {
    height: 17.25rem;
    padding: 3.125rem 6.1875rem;
  }

  .footerContent {
    width: 100%;
    gap: 13.45%;
    flex-direction: row;
  }
}

@media (min-width: 1230px) {
  .footer {
    height: 17.25rem;
    padding: 3.125rem 6.1875rem;
  }

  .footerContent {
    width: 100%;
    gap: 13.7%;
    /* gap: 8.25rem; */
    flex-direction: row;
  }
}

@media (min-width: 1381px) {
  .footer {
    height: 17.25rem;
    padding: 3.125rem 10.875rem;
  }

  .footerContent {
    width: 100%;
    gap: 16.7%;
    /* gap: 8.25rem; */
    flex-direction: row;
  }
}